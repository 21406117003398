/* General styling */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal overflow */
}

/* Styling the columns */
.description-column {
  background-color: #3c767c;
  padding: 20px;
  color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  min-width: 200px; /* Minimum width */
  max-width: 25%; /* Set a max width to prevent excessive growth */
  flex-grow: 1; /* Allow it to grow */
  overflow: auto;
}

.resize-handle {
  width: 5px;
  background-color: #666;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  cursor: ew-resize;
  z-index: 10;
}
.code-column {
  background-color: #00474f;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  flex-grow: 2; /* Allow it to grow more compared to the description column */
  overflow: hidden;
  min-width: 300px; /* Ensures a minimum width */
  height: 100vh; /* Full screen height */
  display: flex;
  flex-direction: column;
}


.input-output-column {
  background-color: #3c767c;
  padding: 20px;
  color: #ffffff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  min-height: 100vh;
  min-width: 200px;
  max-width: 400px;
  flex-shrink: 0; /* Prevent shrinking */
  overflow: auto;
}

/* Code editor styling */
.monaco-editor {
  height: 103% !important; /* Ensure the editor takes full height */
}

.code-editor {
  background-color: #000000; /*Black background*/
  color: #ffffff;         /*  White text*/
  font-family: 'Source Code Pro', monospace;
  font-size: 14px;
  height: 80vh;            /* Full viewport height */
  width: 100vw;             /* Full viewport width */
  position: fixed;          /* Fixed positioning */
  top: 0;                   /* Align to the top of the viewport */
  left: 0;                  /* Align to the left of the viewport */
  border-radius: 0;         /* Remove any rounded corners */
  box-shadow: none;         /* Remove inner shadow */
  z-index: 9999;            /* Ensure it is on top of all other elements */
  overflow: hidden;         /* Prevent scrollbars */
}
/* Button group on the code editor header */
.code-header {
  margin-bottom: 10px;
  margin-left: 25px;
}

/* Resizer for the description column */
.resizer {
  position: absolute;
  width: 5px;
  right: 0;
  top: 0;
  height: 100%;
  cursor: ew-resize;
  background-color: #00d1b2;
  z-index: 100;
}

/* Mobile adjustments */
@media (max-width: 768px) {
  .description-column, .code-column {
    flex: 1 1 auto;
    max-width: 100%;
}
  
  .input-output-column {
    min-height: auto; /* Allow height to be flexible */
  }
}

.monaco-editor {
    background-color: #1e1e1e !important;  /* Dark background */
    color: #d4d4d4 !important; 
    min-height: 90vh;          /*  Light text */
}

.monaco-editor .view-lines {
    background-color: #1e1e1e !important;  /* Dark background for text lines */
    color: #d4d4d4 !important;
    
               /* Light text for the code */
}

.monaco-editor .margin {
    background-color: #1e1e1e !important;  /* Dark background for line numbers */
    color: #858585 !important;  
               /* Lighter text for line numbers */
}

.input-output-column .form-control {
  border: none;
  border-radius: 5px;
  background-color: #1e1e1e; /* Same as Monaco Editor background */
  color: #dcdcdc; /* Text color */
  padding: 10px;
  font-size: 14px;
  min-height: 150px; /* Ensures the textarea is tall enough */
  resize: horizontal; /* Prevents resizing */
}

.input-output-column .form-control:focus {
  outline: none; /* Removes the default focus outline */
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); /* Focus shadow */
}

.input-output-column .form-label {
  color: #dcdcdc; /* Label color */
  font-weight: bold; /* Bold labels */
}


/* Global reset */
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

.coding-challenge-container {
  display: flex;
  height: 100vh;
  background-color: #1e1e1e;
  color: #f2ebeb;
  flex-direction: row;
  overflow: hidden;
}


.questionBar {
  width: 300px;
  transition: width 0.3s ease;
  background-color: #3c767c;
}

.questionBar.hidden {
  width: 0;
  overflow: hidden;
}

.question-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toggle-btn{
   position: absolute;
  top: 10px;
  left: 275px;
  background-color: #599da5;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.toggle-arrow {
  position: absolute;
  top: 10px;
  left: 5px;
  background-color: #599da5;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  border-radius: 0 3px 3px 0;
  cursor: pointer;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.toggle-arrow:hover {
  background-color: #00474f;
}

.description {
  padding: 15px;
  transition: opacity 0.3s ease, max-height 0.3s ease;
  opacity: 1;
  max-height: 100%;
}

.questionBar.hidden .description {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow: hidden;
}

.editor-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: auto;
}

.io-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 10px;
}

.-box .monaco-editor{
  width: 100%;
  height: 25px;
  flex: 1;
}

.io-textarea {
  width: 100%;
  height: 100px;
  resize: none;
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff; /* Ensure it's not disabled visually */
  color: #000;
}

.drag-bar {
  height: 5px;
  background-color: #d0d0d0; /* A slightly darker color for better visibility */
  cursor: ns-resize;
  position: absolute;
  top: 0;
  width: 100%;
  border-bottom: 1px solid #ccc; /* Optional for emphasis */
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1); /* Optional for depth */
}


.loading-spinner {
  margin-top: 20px; /* Adjust the spacing to your preference */
}

/* When .questionBar is hidden */
.questionBar.hidden ~ .console-container {
  left: 0;
  width: 100%;
}

/* When .questionBar is visible */
.questionBar.visible ~ .console-container {
  left: 300px;
  width: calc(100% - 300px);
}

.console-header {
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  align-items: center;
  padding: 10px;
}

.console-container {
  position: fixed;
  bottom: 0; /* Ensure it stays at the bottom */
  transition: transform 0.3s ease; /* Smooth movement */
  height: 300px;
  display: flex;
  transition: transform 0.3s ease;
  flex-direction: column;
  background-color: #3c767c; /* Green color */
  color: #ffffff;
  overflow: hidden;
  cursor: move;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  padding: 10px;
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it appears above everything */
}

.loading-spinner {
  border: 8px solid #f3f3f3; /* Light gray */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-overlay p {
  color: #fff;
  font-size: 18px;
  margin-top: 10px;
}
.button-container {
  display: flex;
  gap: 10px; /* Space between buttons */
}
/* Styles for the buttons */
/* Styles for the buttons */
/* Styles for the buttons */

/* Main container for the buttons */
.button-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Create 3 equal columns */
  gap: 10px; /* Space between buttons */
  position: fixed;
  top: 10%;  /* Adjust vertical placement */
  right: 20px;  /* Position buttons on the right */
  z-index: 10;
}

/* Main container for the buttons */
.button-container {
  display: flex;
  justify-content: flex-end;  /* Align items to the right side */
  gap: 10px; /* Space between buttons */
  direction: rtl; /* Change reading order to right-to-left */
  position: fixed;
  top: 20px;  /* Adjust vertical placement */
  right: 10px;  /* Position buttons on the right */
  z-index: 10;
}

/* Main container for the buttons */
.button-container {
  display: flex;
  justify-content: flex-end;  /* Align items to the right side */
  gap: 10px; /* Space between buttons */
  direction: ltr; /* Set to left-to-right initially */
  position: fixed;
  top: 20px;  /* Adjust vertical placement */
  right: 10px;  /* Position buttons on the right */
  z-index: 10;
}

/* Styling for each button */
.run-btn-coding {
  background-color: #18a148;
  color: #ffffff;
  margin-right: -5px;
  padding: 10px 15px;
  margin-left: 2000px;
  margin-right: -700px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.run-btn-coding:hover {
  background-color: #3c767c;
}

.compile-btn {
  background-color: #149140;
  color: #ffffff;
  margin-right: 164px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.compile-btn:hover {
  background-color: #3c767c;
}


.submit-btn {
  background-color: #1e8b66;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #3c767c;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .button-container {
    flex-direction: column;  /* Stack buttons vertically on smaller screens */
    bottom: 10px;  /* Position at the bottom for mobile */
    right: 10px;
    top: auto;  /* Reset the top value */
  }

  .run-btn-coding, .compile-btn, .submit-btn {
    margin: 5px 0; /* Add space between buttons */
  }
}


.testcase-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  padding-bottom: 10px;
}

.testcase {
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
}

.testcase:hover {
  text-decoration: underline;
}


.io-box {
  display: flex;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the left */
  align-items: flex-start; /* Align items to the left */
  margin: 10px;
  margin-left: 0px; /* Reset margin on the left side */
  /* Optional: Use position if necessary for further adjustments */
  /* position: relative; */
}

.io-textarea {
  width: 50%;
  width: 50%;
  height: 150px;
  background-color: #000000; /* Black color for input and output */
  color: #ffffff;
  resize: none;
}

.console-footer {
  position: absolute;
  bottom: 5px;  /* Increase bottom spacing to avoid overlap */
  bottom: 5px;  /* Increase bottom spacing to avoid overlap */
  right: 10px;
  z-index: 5;  /* Ensure console-footer doesn't overlap buttons */
}

.submit-btn {
  background-color: #149140;
  color: #ffffff;
  padding: 10px 15px;
  margin-right: 50px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 10; /* Ensure this is above run-btn-coding */
  z-index: 10; /* Ensure this is above run-btn-coding */
}

.submit-btn:hover {
  background-color: #3c767c; /* Slightly darker shade for hover effect */
  background-color: #3c767c; /* Slightly darker shade for hover effect */
}









/* Adjust the margin for better positioning */
.run-btn-coding, .submit-btn, .compile-btn {
  margin-left: 20px; /* Smaller margin for better layout */
}
.confirmation-dialog {
  position: fixed;
  background-color: #ffffff;
  width: 30%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the dialog */
  color: black;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(235, 227, 227, 0.15);
  z-index: 9999; /* Ensures the dialog is above other content */
  text-align: center;
}

.confirmation-dialog button {
  margin: 10px;
}

.confirmation-dialog .yes-btn {
  background-color: #149140; /* Green background */
  color: #ffffff; /* White text */
}

/* No Button Style */
.confirmation-dialog .no-btn {
  background-color: #bd2929; /* Red for No button */
  color: #ffffff;
  margin-left: 40px;
}


 
/* Responsive Design */
@media screen and (max-width: 600px) {
  .confirmation-dialog button {
    padding: 8px 15px; /* Smaller padding for mobile */
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}
.confirmation-dialog {
  position: fixed;
  background-color: #ffffff;
  width: 30%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the dialog */
  color: black;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(235, 227, 227, 0.15);
  z-index: 9999; /* Ensures the dialog is above other content */
  text-align: center;
}

.confirmation-dialog button {
  margin: 10px;
}

.confirmation-dialog .yes-btn {
  background-color: #149140; /* Green background */
  color: #ffffff; /* White text */
}

/* No Button Style */
.confirmation-dialog .no-btn {
  background-color: #bd2929; /* Red for No button */
  color: #ffffff;
  margin-left: 40px;
}


 
/* Responsive Design */
@media screen and (max-width: 600px) {
  .confirmation-dialog button {
    padding: 8px 15px; /* Smaller padding for mobile */
    font-size: 14px; /* Adjust font size for smaller screens */
  }
}

/* Ensure buttons are spaced properly */
.testcase-container {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  padding-bottom: 10px;
}

/* Add a background card around the Monaco Editor */
/* Add a background card around the Monaco Editor and language selector */
.monaco-card {
  background-color: #00474f; /* Green background */
  padding: 20px; /* Add padding around the editor */
  border-radius: 10px; /* Optional: for rounded corners */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: shadow effect */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

/* Monaco Editor itself */
.monaco-editor-container {
  background-color: #1e1e1e; /* Monaco editor dark theme */
  width: 100%;
  height: 100%; /* Set a fixed height for the editor */
  border-radius: 8px; /* Optional: to round the editor corners */
}
.language-selector {
  background-color: #00474f; /* Grey background */
  padding: 8px 16px; /* Button size */
  border-radius: 8px;
  margin-right: 1000px;
  display: inline-block; /* Align it as a button */
}

.language-selector select {
  background-color: #808080; /* Grey background */
  color: white; /* White text */
  border: none; /* Remove border */
  padding: 8px 12px; /* Padding for the dropdown */
  font-size: 16px; /* Medium size text */
  font-weight: bold; /* Bold text */
  border-radius: 8px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor */
  width: 150px; /* Medium width */
}

.language-selector select:focus {
  outline: none; /* Remove outline on focus */
}
