.dashboard-container {
    display: flex;
    min-height: 100vh;
  }
  
  .sidebar {
    width: 200px;
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
  }
  
  .sidebar h2 {
    font-size: 1.2em;
    margin-bottom: 15px;
  }
  
  .sidebar ul {
    list-style: none;
    padding: 0;
  }
  
  .sidebar li {
    margin: 10px 0;
    cursor: pointer;
    color: #333;
    transition: color 0.3s;
  }
  
  .sidebar li:hover {
    color:  #246367;
  }
  
  .dashboard-content {
    flex: 1;
    padding: 20px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .cards-container {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  
  .card {
    flex: 1;
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .card h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }
  
  .button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .department-button, .section-button {
    padding: 12px 20px;
    font-size: 1.1em;
    border: none;
    color: rgb(6, 6, 23);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .department-button:hover, .section-button:hover {
    background-color:  #246367;
  }
  
  /* Add a new style for the selected state */
  .selected {
    background-color:  #246367; /* Set the selected background color */
    color: white; /* Optional: Change text color for better contrast */
  }
  
  
  
  .card h2 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }
  
  .card p {
    font-size: 1.1em;
    margin: 5px 0;
  }
  
  .dashboard-container {
    display: flex;
  }
  
  .sidebar {
    width: 200px;
    padding: 20px;
    background-color: #f4f4f4;
  }
  
  .dashboard-content {
    flex: 1;
    padding: 20px;
  }
  
  .card-row {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .card {
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 100%;
  }
  
  .button-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  button {
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .progress-bar {
    background-color: smokewhite;
    border-radius: 8px;
    height: 20px;
    width: 100%;
    margin-top: 10px;
  }
  
  .progress {
    background-color: grey;
    height: 100%;
    border-radius: 8px;
    transition: width 0.5s ease; /* Smooth transition for width change */
  }
  
  .student-card {
    position: relative; /* Allows positioning of the button inside the card */
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 17px;
    width: 97%;
  }
  
  .arrow-button {
    position: absolute; /* Position the button absolutely within the card */
    top: 50%; /* Center it vertically */
    right: 10px; /* Adjust right position */
    transform: translateY(-50%); /* Center the button vertically using transform */
    background: none; /* Remove default button background */
    border: none; /* Remove default button border */
    font-size: 20px; /* Size of the arrow */
    cursor: pointer; /* Change cursor to pointer */
    color: #010911; /* Color for the arrow, can be customized */
    transition: color 0.2s; /* Smooth color change on hover */
  }
  
  .arrow-button:hover {
    color: rgb(0, 0, 0); /* Darker color on hover */
  }
  .square-button {
    position: absolute; /* Position the button absolutely within the card */
    top: 50%; /* Center it vertically */
    right: 10px; /* Adjust right position */
    transform: translateY(-50%); /* Center the button vertically using transform */
    width: 40px; /* Width of the square */
    height: 40px; /* Height of the square */
    background-color: #246367; /* Background color of the button */
    border: none; /* Remove default button border */
    border-radius: 5px; /* Optional: slight rounding of corners */
    color: white; /* Color of the symbol */
    font-size: 20px; /* Size of the symbol */
    cursor: pointer; /* Change cursor to pointer */
    display: flex; /* Flexbox to center the symbol */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    transition: background-color 0.2s; /* Smooth background change on hover */
  }
  
  .square-button:hover {
    background-color: #2fe0e0; /* Darker background on hover */
  }
  
  .dashboard-container {
    position: relative;
  }
  
  .menu-button-container {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
  }
  
  .menu-button {
    font-size: 24px;
    background: none;
    border: none;
    cursor: pointer;
    position: sticky;
  }
  
  .dropdownn-menu {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    width: 150px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }
  
  .dropdownn-menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .dropdownn-menu li {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdownn-menu li:hover {
    background-color: #f0f0f0;
  }
  
  /* List Items (Search and Admission Enquiry) */
  .menu-items {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between; /* Distribute space between list items */
    width: 100%;
    margin-bottom: 10px; /* Space between list items and search box */
    position: sticky;
  }
  
  /* Search Box Wrapper to align the search box properly */
  .search-box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  /* Search Box */
  .search-box {
    display: flex;
    align-items: center;
  }
  
  .search-box input {
    margin-right: 10px;
    padding: 5px;
  }
  
  .search-box button {
    padding: 5px;
  }
  /* Dropdown Menu */
  .dropdownn-menu {
    position: relative;
    width: 250px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    font-family: Arial, sans-serif;
    z-index: 1000;
    min-height: 120px; /* Set a minimum height to prevent shrinking */
    transition: min-height 0.3s ease; /* Smooth transition when expanding or collapsing */
  }
  /* List Items (Search and Admission Enquiry) */
  .menu-items {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Space between each item */
    
  }
  
  /* Each List Item */
  .menu-items li {
    padding: 10px;
    cursor: pointer;
    background-color: #f7f7f7;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .menu-items li:hover {
    background-color:  #246367;
    color: white;
  }
  
  /* Search Box Wrapper to align the search box properly */
  .search-box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
  }
  
  /* Search Box */
  .search-box {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px; /* Space between input and button */
  }
  
  /* Search Box Input */
  .search-box input {
    width: 150px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
    transition: border-color 0.3s ease;
  }
  
  .search-box input:focus {
    outline: none;
    border-color:  #246367;
  }
  
  /* Search Button */
  .search-box button {
    padding: 7px px;
    background-color:  #246367;
    border: none;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
  }
  
  .search-box button:hover {
    background-color: #0056b3;
  }
  
  
  /* Fix the position of the menu button */
.menu-button-container {
  position: fixed;
  bottom: 200px; /* Moves the buttons closer to the bottom */
  top: 113px;
  right: 17px;
  z-index: 2000; /* Ensures it stays above other elements */
}

.menu-button {
  font-size: 24px;
  cursor: pointer;
}

/* Dropdown menu styling */
.dropdownn-menu {
  position: absolute;
  
  top: 40px;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 8px 16px rgba(0,0,0,0.2);
  padding: 10px;
}

.menu-items {
  list-style-type: none;
  padding: 0;
}

.menu-items li {
  padding: 8px 16px;
  cursor: pointer;
}

.menu-items li:hover {
  background-color: #0b3f4a;
}
