/* Container for scrolling effect */
.logos-container {
  overflow: hidden;
  position: relative;
  background: whitesmoke; /* Background color or gradient */
  padding: 10px 0;
  box-shadow: 0 4px 6px #017a8c; /* Optional: adds a shadow */
  margin-top: 20px;
}

/* Wrapper for duplicating logos */
.logos-wrapper {
  display: flex;
  width: max-content; /* Ensures container expands as needed */
  animation: scroll-left 30s linear infinite;
}

/* Flex container for logos */
.logos {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

/* Individual logos styling */
.logos img {
  height: 50px; /* Adjust height as needed */
  margin: 0 15px; /* Space between logos */
  transition: transform 0.3s ease; /* Optional: adds smooth zoom effect */
}

/* Zoom effect on hover */
.logos img:hover {
  transform: scale(1.1);
}

/* Keyframes for scrolling effect */
@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .logos img {
    height: 40px; /* Smaller logos for mobile */
    margin: 0 10px;
  }
}

@media (max-width: 480px) {
  .logos img {
    height: 30px; /* Even smaller logos for very small screens */
    margin: 0 5px;
  }
}
