.p-4 {
  padding: 1rem;
}

.w-full {
  width: 100%;
}

.text-3xl {
  font-size: 2rem;
}

.font-bold {
  font-weight: bold;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

/* Summary cards */
.grid {
  display: grid;
  gap: 1rem;
}

.md\:grid-cols-4 {
  grid-template-columns: repeat(4, 1fr);
}

.bg-white {
  background-color: #ffffff;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.shadow {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.p-6 {
  padding: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
}

.font-semibold {
  font-weight: 600;
}

.text-gray-700 {
  color: #4a5568;
}

.text-3xl {
  font-size: 2rem;
}

.text-blue-600 {
  color: #2563eb;
}

.text-green-600 {
  color: #16a34a;
}

.text-cyan-600 {
  color: #0891b2;
}

.space-y-2 > * + * {
  margin-top: 0.5rem;
}

/* Charts container */
.h-96 {
  height: 24rem;
}

/* Responsive styles */
@media (max-width: 768px) {
  .grid {
    grid-template-columns: 1fr;
  }

  .md\:grid-cols-4 {
    grid-template-columns: 1fr;
  }
}
