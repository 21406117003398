.footer {
  background: linear-gradient(135deg, #263443, #336b74); /* Gradient background */
  color: #FFA003; /* Yellow font color */
  padding: 5px 0; /* Minimized padding for smaller height */
  font-size: 10px; /* Smaller font size */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.footer-center p,
.footer-right p {
  color: #FFA003; /* Yellow font color */
  font-weight: bold;
  margin: 0; /* No extra spacing */
  font-size: 12px; /* Reduced font size */
}

.footer-left,
.footer-center,
.footer-right {
  display: flex;
  align-items: center;
  justify-content: center; /* Center content horizontally */
  flex-direction: column; /* Stack items vertically if necessary */
  text-align: center; /* Center text */
  margin-bottom: 5px; /* Minimized space between rows */
}

/* Social icons */
.footer-left .social-icons a {
  color: white; /* Icon color */
  font-size: 20px; /* Smaller icon size */
  transition: color 0.3s, transform 0.3s;
  margin: 0 4px; /* Reduced spacing between icons */
}

.footer-left .social-icons a:hover {
  color: #fff; /* White color on hover */
  transform: scale(1.1); /* Slightly enlarge icon on hover */
}

.footer h5,
.footer h6 {
  margin-bottom: 10px;
  font-weight: bold;
  color: #ffd700; /* Yellow color */
  text-align: center;
}

.footer h6 {
  font-size: 12px; /* Smaller font size */
  text-transform: uppercase;
  border-bottom: 1px solid #ffd700; /* Reduced border thickness */
  padding-bottom: 3px; /* Reduced padding for compactness */
}

.footer-links a {
  color: #ffd700; /* Yellow font color */
  text-decoration: none;
  font-size: 10px; /* Smaller link font size */
}

.footer-links a:hover {
  text-decoration: underline;
}
