/* Container Styles */
.login-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  padding: 20px;
}

/* Card Style for Login Form */
.login-container .form-container {
  background: white;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 450px;
}

/* Heading */
.login-container h2 {
  color: #333;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

/* Form Inputs */
.login-container .form-control {
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #ddd;
  font-size: 14px;
}

.login-container .form-control:focus {
  border-color: #6c63ff;
  box-shadow: 0 0 0 0.2rem rgba(108, 99, 255, 0.25);
}

/* Button */
.login-container .btn {
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
}

.login-container .btn-primary {
  background-color: #6c63ff;
  border: none;
}

.login-container .btn-primary:hover {
  background-color: #574bdf;
}

/* Links */
.login-container p {
  text-align: center;
  font-size: 14px;
  color: #555;
}

.login-container p button {
  color: #6c63ff;
  text-decoration: underline;
  border: none;
  background: none;
  cursor: pointer;
}

.login-container p button:hover {
  color: #574bdf;
}

/* Alerts */
.login-container .alert {
  border-radius: 8px;
  font-size: 14px;
}

/* Mobile Responsiveness */
@media (max-width: 576px) {
  .login-container .form-container {
    padding: 20px;
  }

  .login-container h2 {
    font-size: 20px;
  }
}
