/* General layout */
.quiz-layout {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 20px;
  background-color: #f9fafc;
  padding: 20px;
  min-height: 100vh;
  overflow: hidden;
}

/* Question Section */
.question-section {
  flex: 3;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  max-height: 90vh;
}

.question-section h2 {
  margin-bottom: 20px;
  font-size: 2rem;
  color: #111827;
  font-weight: 700;
  border-left: 5px solid #2563eb;
  padding-left: 15px;
}

.question-section p {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.8;
  color: #374151;
}

/* Options Grid */
.options-grid {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.option-item {
  padding: 15px;
  background: #f9fafb;
  border: 1px solid #e5e7eb;
  border-radius: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  color: #374151;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.option-item:hover {
  background-color: #eff6ff;
  border-color: #93c5fd;
  transform: scale(1.02);
}

.option-item.selected {
  background: #2563eb;
  color: #ffffff;
}

/* Navigation Buttons */
.navigation-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.navigation-buttons button {
  padding: 8px 15px;
  border: none;
  border-radius: 10px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #ffffff;
  background-color: #2563eb;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.navigation-buttons button:hover {
  background-color: #1d4ed8;
  transform: scale(1.05);
}

.navigation-buttons button:disabled {
  background-color: #d1d5db;
  cursor: not-allowed;
}

/* Side Panel */
.side-panel {
  flex: 1.5;
  background: #ffffff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 350px;
  max-height: 90vh;
  overflow-y: auto;
  gap: 20px;
}

/* Timer container */
.timer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timer-text {
  font-size: 2rem;
  font-weight: 700;
  color: #111827;
  letter-spacing: 1px;
}

.timer-text.red {
  color: #dc2626;
  animation: pulse 1s infinite;
}

/* Palette container */
.palette-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.palette-item {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f3f4f6;
  color: #111827;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.palette-item:hover {
  background-color: #dbeafe;
  transform: scale(1.1);
}

.palette-item.answered {
  background-color: #22c55e;
  color: white;
}

.palette-item.marked {
  background-color: #facc15;
  color: white;
}

.palette-item.current {
  border: 3px solid #2563eb;
}

/* Attempt Info */
.attempt-info {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.attempt-info .info-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 150px;
  border-radius: 20px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  color: white;
  font-weight: bold;
  text-align: center;
  background-image: url('https://via.placeholder.com/250x150.png?text=Box+Background');
  background-size: cover;
  background-position: center;
}
/* Attempt Info Section */
.attempt-info {
  display: flex;
  flex-direction: column; /* Stack boxes vertically */
  gap: 10px; /* Space between boxes */
  align-items: center; /* Center the boxes horizontally */
  justify-content: flex-start; /* Align boxes to the start */
}

/* Individual Box */
.attempt-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between; /* Space out text */
  border-radius: 8px; /* Reduced border radius */
  padding: 10px 15px; /* Smaller padding */
  background: #f3f4f6; /* Neutral background color */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 80%; /* Limited width */
  font-size: 1rem; /* Adjusted font size */
  font-weight: 600; /* Bold text for emphasis */
  color: #111827; /* Neutral text color */
  text-align: left;
}

/* Attempted Box */
.attempt-card.attempted {
  background: #e0f7ef; /* Light green background */
  color: #047857; /* Dark green text */
}

/* Unattempted Box */
.attempt-card.unattempted {
  background: #fee2e2; /* Light red background */
  color: #b91c1c; /* Dark red text */
}

/* Responsive Design */
@media (max-width: 768px) {
  .attempt-info {
    width: 100%; /* Full width on small screens */
    align-items: center; /* Center-align boxes for smaller screens */
  }

  .attempt-card {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
    padding: 8px 12px; /* Adjust padding */
  }
}



/* Submit Button */
.submit-button-container {
  display: flex;
  justify-content: center;
  margin-top: auto;
}

.submit-button {
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background-color: #ef4444;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.submit-button:hover {
  background-color: #dc2626;
  transform: scale(1.1);
}

.submit-button:disabled {
  background-color: #e5e7eb;
  cursor: not-allowed;
}

/* Confirmation Dialog */
.confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  z-index: 1000;
}

.confirmation-dialog p {
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: #374151;
}

.dialog-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.dialog-buttons button {
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  color: white;
  background-color: #2563eb;
}

.dialog-buttons button:hover {
  background-color: #1d4ed8;
}

.dialog-buttons button:nth-child(2) {
  background-color: #ef4444;
}

.dialog-buttons button:nth-child(2):hover {
  background-color: #dc2626;
}

/* Keyframes for pulse animation */
@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

/* Responsive Layout */
@media (max-width: 768px) {
  .quiz-layout {
    flex-direction: column;
  }

  .side-panel {
    flex: 1.5;
    background: #ffffff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 350px;
    gap: 20px;
  }
}
