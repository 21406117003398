/* Navigation Bar Styles */
.navigation-container {
    display: flex;
    align-items: center;
    padding: 10px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
}

/* Logo Styling */
.logo {
    height: 60px;
    margin-left: 30px;
   
}

/* Navbar Styles */
.custom-navbar {
    flex-grow: 1;
    border-radius: 50px;
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(15px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 0 40px;
    transition: background-color 0.3s ease-in-out;
}

.custom-navbar:hover {
    background: rgba(255, 255, 255, 0.35);
}

/* Sticky positioning */
.sticky-top {
    position: sticky !important;
    top: 0;
}

/* Brand Name Styling */
.brand-name {
    font-weight: bold;
    color: #FFA003;
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
   
    
}

.flash {
    color: #3B3B3B;
}

/* Navbar Link Styles */
.navbar-nav .nav-link {
    color: #00454f !important;
    font-weight: 500;
    font-size: 1.1rem;
    margin-left: 15px;
    transition: color 0.3s, transform 0.3s;
}

.navbar-nav .nav-link:hover {
    color: #000000 !important;
    transform: scale(1.05);
}

/* Profile Dropdown */
.profile-dropdown {
    font-size: 1.1rem;
    color: #00454f;
}

.profile-icon {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.profile-dropdown:hover {
    color: #127584;
}

/* Get Started Button */
.get-started-btn {
    background-color: #00454f;
    color: #fff;
    border-radius: 30px;
    padding: 8px 16px;
    font-size: 14px;
    text-transform: uppercase;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.get-started-btn:hover {
    background-color: #0e7d8f;
    transform: scale(1.05);
}

.get-started-btn:active {
    transform: scale(0.98);
    box-shadow: none;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .custom-navbar {
        padding: 10px;
        border-radius: 30px;
        background: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(10px);
    }

    .brand-name {
        font-size: 1.4rem;
    }

    .logo {
        height: 50px;
    }

    .navbar-nav .nav-link {
        font-size: 1rem;
    }

    .acc-creation .nav-link {
        font-size: 1.1rem;
    }
}

@media (max-width: 576px) {
    .custom-navbar {
        padding: 5px;
        border-radius: 0;
        background: rgba(255, 255, 255, 0.15);
    }

    .brand-name {
        font-size: 1.2rem;
        text-align: center;
    }

    .logo {
        height: 40px;
    }

    .navbar-nav .nav-link {
        font-size: 0.9rem;
        margin-left: 10px;
    }

    .acc-creation .nav-link {
        font-size: 1rem;
    }

    .profile-icon {
        width: 25px;
        height: 25px;
    }

    .get-started-btn {
        padding: 6px 12px;
        font-size: 12px;
    }
}
