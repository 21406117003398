.banner {
    background: linear-gradient(135deg, #001920 0%, #017a8c 100%);
    color: white;
    padding: 50px 20px;
    border-radius: 8px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .banner-text {
    animation: slideInFromLeft 1.5s ease-in-out;
    text-align: center;
  }
  
  .banner-text h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 1.2;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .banner-text .highlight {
    color: #ffa003;
  }
  
  .banner-text p {
    font-size: 1.1rem;
    margin-bottom: 25px;
    animation: fadeIn 2s ease-in-out;
  }
  
  .create-test-btn {
    background-color: #00cc66;
    color: #ffffff;
    width: 10em;
    height: 3em;
    border-radius: 30px;
    font-size: 15px;
    font-family: inherit;
    border: none;
    position: relative;
    overflow: hidden;
    z-index: 1;
    box-shadow: 0 4px 8px hsla(188, 99%, 28%, 0.5);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .create-test-btn::before {
    content: '';
    width: 0;
    height: 3em;
    border-radius: 30px;
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(to right, #0fd850 0%, #f9f047 100%);
    transition: 0.5s ease;
    display: block;
    z-index: -1;
  }
  
  .create-test-btn:hover::before {
    color: #001920;
    width: 10em;
  }
  
  .create-test-btn:hover {
    box-shadow: 0 8px 16px rgba(0, 204, 102, 0.4);
    transform: scale(1.02);
  }
  
  .banner-icons {
    display: flex;
    justify-content: center;
    font-size: 1rem;
    margin-top: 20px;
    animation: fadeIn 3s ease-in-out;
  }
  
  .banner-icons span {
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 0 10px;
  }
  
  /* Image Styling for Card Effect */
  .banner-image {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    animation: fadeInUp 1s ease-in-out;
    flex: 1;
  }
  
  .banner-image img {
    width: 100%;
    max-width: 280px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
    margin: 10px;
    border: 2px solid #fff;
    transform: rotate(-2deg);
  }
  
  .banner-image img:hover {
    transform: scale(1.05) rotate(0);
  }
  
  .banner-image img:nth-child(2) {
    transform: rotate(2deg);
  }
  
  @media (min-width: 768px) {
    .banner {
      flex-direction: row;
      text-align: left;
    }
  
    .banner-text {
      text-align: left;
    }
  
    .banner-text h1 {
      font-size: 3rem;
    }
  
    .banner-text p {
      font-size: 1.2rem;
    }
  
    .banner-icons {
      justify-content: flex-start;
    }
  
    .banner-icons span {
      margin: 0 20px;
    }
  }
  
  @media (min-width: 992px) {
    .banner-text h1 {
      font-size: 3.5rem;
    }
  
    .banner-text p {
      font-size: 1.3rem;
    }
  }
  
  /* Animation Keyframes */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  @keyframes pulse {
    from {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    to {
      transform: scale(1);
    }
  }
  